import { useStaticQuery, graphql } from "gatsby"
import { SiteOptions } from "../types/types"

export const useSiteOptions = () => {
    const {
        wp: {
            siteOptions: { siteOptions },
        },
    } = useStaticQuery(
        graphql`
            query SiteOptions {
                wp {
                    siteOptions {
                        siteOptions {
                            siteFavicon {
                                sourceUrl
                            }
                            showreel
                            videoOembedDimensions
                        }
                    }
                }
            }
        `
    )

    return siteOptions as SiteOptions
}
