import fetch from "isomorphic-fetch"
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

const client = new ApolloClient({
    link: ApolloLink.from([
        setContext((req, prev) => {
            return {
                headers: {
                    ...prev.headers,
                    // As long as we are on the same domain with our WP install and Gatsby front end,
                    // we can use the x-wp-nonce header to authenticate and fetch previews.
                    "X-WP-Nonce": req.variables.nonce ? req.variables.nonce : "",
                },
            }
        }),
        new HttpLink({
            uri: process.env.GATSBY_WPGRAPHQL_URL,
            credentials: "include",
        }),
        new ApolloLink((operation, forward) => {
            if (operation.variables) {
                // We're prefixing all typenames here, it might be better to
                // only prefix the ones that gatsby-source-wordpress does
                const prefixTypename = (key, value) => (key === "__typename" ? `Wp${value}` : value)
                operation.variables = JSON.parse(JSON.stringify(operation.variables), prefixTypename)
            }
            return forward(operation)
        }),
    ]),
    fetch,
    cache: new InMemoryCache(),
})

export default client
