import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { PageProps } from "gatsby"
import "../styles/main.css"
import Nav from "./Nav"
import Splash from "../components/Splash"
import Hero from "../components/Hero"
import { AnimatePresence } from "framer-motion"
import { useSiteOptions } from "../hooks/use-site-options"
import { LocationWithState, PageContext } from "../types/types"
import { useTransition } from "../hooks/use-transition"

interface LayoutProps extends PageProps {
    data: {
        wpPage: {
            title: string
        }
    }
    location: LocationWithState
    pageContext: PageContext
}

function Layout({ data, children, location, path, pageContext }: LayoutProps) {
    const [dontShowSplash, setDontShowSplash] = useState<boolean | undefined>()
    const [heroStartsVisible, setHeroStartsVisible] = useState(false)
    const [reqPageContext, setReqPageContext] = useState(pageContext)

    const siteOptions = useSiteOptions()
    const { dynamicPageContext } = useTransition()

    useEffect(() => {
        setReqPageContext(dynamicPageContext ?? pageContext)
    }, [dynamicPageContext, pageContext])

    useEffect(() => {
        // Only run this for the first page load, i.e. if dontShowSplash is undefined
        if (typeof dontShowSplash === "undefined") {
            // If pageContext.splashText is null or undefined then we're on a page without a splash,
            // so don't show splash for future routes. N.B. when the splash shows (onSplashShow),
            // dontShowSplash is set to true for future routes so we only see it once
            setDontShowSplash(reqPageContext.splashText == null)
        }
    }, [reqPageContext.splashText])

    useEffect(() => {
        setHeroStartsVisible(reqPageContext.isFrontPage && location.state?.titleClick === true)
    }, [location.state, reqPageContext.isFrontPage])

    return (
        <>
            {!dontShowSplash && reqPageContext.splashText && (
                <Splash text={reqPageContext.splashText} onSplashShown={() => setDontShowSplash(true)} waitForHero={reqPageContext.isFrontPage && siteOptions.showreel != null} />
            )}
            <Hero videoOembed={siteOptions.showreel} videoDimensions={siteOptions.videoOembedDimensions} isFrontPage={reqPageContext.isFrontPage} heroStartsVisible={heroStartsVisible} />
            <Nav
                isHome={path === "/"}
                isPrivate={reqPageContext.isPrivate}
                parent={reqPageContext.parent}
                currentTitle={data?.wpPage?.title ?? reqPageContext.title}
                altNav={reqPageContext.altNav}
                showHero={reqPageContext.isFrontPage && siteOptions.showreel != null}
            />
            <AnimatePresence initial={false}>{children}</AnimatePresence>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
