// extracted by mini-css-extract-plugin
export var fadeIn = "Nav-module--fadeIn--806d2";
export var linkActive = "Nav-module--linkActive--6bc0a";
export var nav = "Nav-module--nav--b80cf";
export var navLinks = "Nav-module--navLinks--19920";
export var navLinksFew = "Nav-module--navLinksFew--7a945";
export var navLinksSingle = "Nav-module--navLinksSingle--a1fcb";
export var navMobileToggle = "Nav-module--navMobileToggle--680ee";
export var navMobileToggleFixed = "Nav-module--navMobileToggleFixed--bd33e";
export var navMobileToggleHidden = "Nav-module--navMobileToggleHidden--5802b";
export var titleEl = "Nav-module--titleEl--ad1cf";
export var titleElFixed = "Nav-module--titleElFixed--9d08e";
export var titlePrivateText = "Nav-module--titlePrivateText--f223b";