import React, { createContext, useState } from "react"
import { PageContext } from "../types/types"

interface Props {
    children: React.ReactNode
}

interface TransitionState {
    isFirstLoad: boolean
    isTransitioning: boolean
    dynamicPageContext?: PageContext
    setIsFirstLoad: Function
    setIsTransitioning: Function
    setDynamicPageContext: Function
}

const defaultState = {
    isFirstLoad: true,
    isTransitioning: false,
    dynamicPageContext: undefined,
    setIsFirstLoad: () => {},
    setIsTransitioning: () => {},
    setDynamicPageContext: () => {},
}

const TransitionContext = createContext<TransitionState>(defaultState)

export default TransitionContext

export const TransitionProvider = ({ children }: Props) => {
    const [isFirstLoad, setIsFirstLoad] = useState(defaultState.isFirstLoad)
    const [isTransitioning, setIsTransitioning] = useState(defaultState.isTransitioning)
    const [dynamicPageContext, setDynamicPageContext] = useState(defaultState.dynamicPageContext)

    return (
        <TransitionContext.Provider
            value={{
                isFirstLoad,
                isTransitioning,
                dynamicPageContext,
                setIsFirstLoad,
                setIsTransitioning,
                setDynamicPageContext,
            }}
        >
            {children}
        </TransitionContext.Provider>
    )
}
