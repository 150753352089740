import { useEffect, useState } from "react"

export const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState<string>("")

    const queries = {
        mobile: `(max-width: 799px)`,
        tablet: `(min-width: 800px) and (max-width: 1059px)`,
        desktop: `(min-width: 1060px)`,
    }

    const getBreakpoint = () => {
        // Prevents SSR issues
        if (typeof window !== "undefined") {
            for (const [key, query] of Object.entries(queries)) {
                if (window.matchMedia(query).matches) {
                    return key
                }
            }
        }

        return ""
    }

    const handleChange = () => {
        setBreakpoint(getBreakpoint())
    }

    useEffect(() => {
        const matchMedias = [window.matchMedia(queries.mobile), window.matchMedia(queries.tablet), window.matchMedia(queries.desktop)]

        handleChange()

        matchMedias.forEach((matchMedia) => {
            if (matchMedia.addListener) {
                matchMedia.addListener(handleChange)
            } else {
                matchMedia.addEventListener("change", handleChange)
            }
        })

        return () => {
            matchMedias.forEach((matchMedia) => {
                if (matchMedia.removeListener) {
                    matchMedia.removeListener(handleChange)
                } else {
                    matchMedia.removeEventListener("change", handleChange)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return breakpoint
}
