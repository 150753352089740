import React from "react"
import Layout from "./src/components/Layout"
import { TransitionProvider } from "./src/context/TransitionContext"

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
    return <TransitionProvider>{element}</TransitionProvider>
}

export const shouldUpdateScroll = () => {
    if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual"
    }

    return false
}
