import React, { useState, useEffect } from "react"
import { splash, splashInner } from "../styles/components/Splash.module.css"

interface Props {
    text?: string | null
    onSplashShown?: Function
    waitForHero: boolean
}

export default function Splash({ text, onSplashShown, waitForHero }: Props) {
    if (!text) {
        return null
    }

    const [timeoutElapsed, setTimeoutElapsed] = useState(false)

    useEffect(() => {
        if (typeof onSplashShown === "function") {
            onSplashShown()
        }
        setTimeout(() => {
            setTimeoutElapsed(true)
            
            if (!waitForHero) {
                document.body.classList.add("hide-splash")
            }
        }, 2000)
    }, [])

    const handleSplashClick = () => {
        if (timeoutElapsed) {
            document.body.classList.add("hide-splash")
        }
    }

    return (
        <div className={splash} data-timeout-elapsed={timeoutElapsed} onClick={handleSplashClick}>
            <div className={splashInner}>{text}</div>
        </div>
    )
}
