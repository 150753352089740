const preventDefault = (e: TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()

    return false
}

export const disableTouchScroll = () => {
    document.addEventListener("touchmove", preventDefault, { passive: false })
}

export const enableTouchScroll = () => {
    // Prevent typescript error as passing passive in the options is a non-standard workaround for some browsers
    const opts: AddEventListenerOptions & EventListenerOptions = { passive: false }
    document.removeEventListener("touchmove", preventDefault, opts)
}
