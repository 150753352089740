import { useContext } from "react"
import TransitionContext from "../context/TransitionContext"

export const useTransition = () => {
    const context = useContext(TransitionContext)

    if (!context) {
        throw new Error("useTransition hook was used but a TransitionContext.Provider was not found in the parent tree. Make sure this is used in a component that is a child of TransitionProvider.")
    }

    return context
}
