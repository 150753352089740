import React, { useState, useEffect, useCallback } from "react"
import VimeoPlayer from "@vimeo/player"
import { debounce } from "debounce"
import { motion, useInView } from "framer-motion"
import { useTransition } from "../hooks/use-transition"
import { globalHistory } from "@reach/router"
import { hero, heroVideo, heroPlaceholder } from "../styles/components/Hero.module.css"
import { SiteOptions } from "../types/types"

interface Props {
    videoOembed: NonNullable<NonNullable<SiteOptions["showreel"]>> | null
    videoDimensions: NonNullable<NonNullable<SiteOptions["videoOembedDimensions"]>> | null
    isFrontPage: boolean
    heroStartsVisible: boolean
}

interface DivRef {
    (node: HTMLDivElement | null): boolean
    current: HTMLDivElement | null
}

export default function Hero({ videoOembed, videoDimensions, isFrontPage, heroStartsVisible }: Props) {
    if (!videoOembed) {
        return null
    }

    const [heroAspectRatio, setHeroAspectRatio] = useState<number | undefined>()
    const [videoWrapWidth, setVideoWrapWidth] = useState<string | undefined>()
    const [videoWrapHeight, setVideoWrapHeight] = useState<string | undefined>()
    const [player, setPlayer] = useState<VimeoPlayer | undefined>()
    const [goingHome, setGoingHome] = useState(false)

    const { isTransitioning } = useTransition()

    useEffect(() => {
        const unsubscribeHistory = globalHistory.listen(({ location }) => {
            setGoingHome(location.pathname === "/")
        })

        return () => {
            unsubscribeHistory()
        }
    }, [])

    // Using refs in useEffect calls doesn't really work - see below
    // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const placeholderRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            setHeroAspectRatio(node.offsetHeight / node.offsetWidth)
        }

        placeholderRef.current = node
    }, []) as DivRef

    const isInView = useInView(placeholderRef)

    // Using refs in useEffect calls doesn't really work - see below
    // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const videoWrapRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (typeof player === "undefined" && node != null && videoOembed) {
                const tmpPlayer = new VimeoPlayer(node, {
                    url: videoOembed,
                    width: 1920,
                    background: true,
                    autopause: false,
                })

                tmpPlayer.on("playing", () => {
                    document.body.classList.add("hero-loaded")
                    tmpPlayer.off("playing")
                })

                tmpPlayer
                    .ready()
                    .then(() => {
                        setPlayer(tmpPlayer)
                    })
                    .catch((error) => {
                        document.body.classList.add("hero-loaded")
                        console.log(error)
                    })
            }

            videoWrapRef.current = node
        },
        [videoOembed]
    ) as DivRef

    useEffect(() => {
        return () => {
            player?.destroy()
        }
    }, [])

    useEffect(() => {
        if ((isFrontPage && isInView) || (isTransitioning && goingHome)) {
            player?.play()
        } else {
            player?.pause()
        }
    }, [isFrontPage, isInView, isTransitioning, goingHome])

    const debouncedOnresize = debounce(() => {
        if (placeholderRef.current) {
            setHeroAspectRatio(placeholderRef.current.offsetHeight / placeholderRef.current.offsetWidth)
        }
    }, 300)

    useEffect(() => {
        if (videoDimensions?.[1] && videoDimensions?.[0] && heroAspectRatio && placeholderRef.current) {
            const aspectRatio = videoDimensions?.[1] / videoDimensions?.[0]
            if (heroAspectRatio < aspectRatio) {
                setVideoWrapWidth("100%")
                setVideoWrapHeight(`${Math.round(placeholderRef.current.offsetWidth * aspectRatio)}px`)
            } else {
                setVideoWrapWidth(`${Math.round(placeholderRef.current?.offsetHeight / aspectRatio)}px`)
                setVideoWrapHeight("100%")
            }
        }
    }, [videoDimensions?.[0], videoDimensions?.[1], heroAspectRatio, placeholderRef.current])

    useEffect(() => {
        window.addEventListener("resize", debouncedOnresize)

        return () => {
            window.removeEventListener("resize", debouncedOnresize)
        }
    }, [])

    const heroVariants = {
        show: {
            opacity: 1,
        },
        hide: {
            opacity: 0,
        },
    }

    return (
        <>
            <motion.div
                className={hero}
                variants={heroVariants}
                animate={isFrontPage && ((isTransitioning && goingHome && heroStartsVisible) || !isTransitioning) ? "show" : "hide"}
                transition={{ duration: 1 }}
                style={{
                    zIndex: isTransitioning && goingHome && heroStartsVisible ? 8 : "",
                }}
            >
                <div ref={videoWrapRef} className={heroVideo} style={{ width: videoWrapWidth, height: videoWrapHeight }} />
            </motion.div>
            <div ref={placeholderRef} className={heroPlaceholder}></div>
        </>
    )
}
