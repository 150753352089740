import React, { ReactNode, useEffect, useState, useRef } from "react"
import { Link } from "gatsby"
import { useInView } from "framer-motion"
import animateScrollTo from "animated-scroll-to"
import { debounce } from "debounce"
import { useBreakpoint } from "../hooks/use-breakpoint"
import { disableTouchScroll, enableTouchScroll } from "../utils/touch-scroll"
import {
    nav,
    titleEl,
    titleElFixed,
    titlePrivateText,
    navLinks,
    navLinksFew,
    navLinksSingle,
    linkActive,
    navMobileToggle,
    navMobileToggleFixed,
    navMobileToggleHidden,
} from "../styles/components/Nav.module.css"
import { AltNav, ParentNavNode } from "../types/types"

interface Props {
    isHome: boolean
    isPrivate: boolean
    showHero: boolean
    currentTitle: string
    parent: ParentNavNode | null
    altNav: AltNav | null
}

export default function Nav({ isHome, isPrivate, showHero, currentTitle, parent, altNav }: Props) {
    const [navIsVisible, setNavIsVisible] = useState(false)
    const [titleIsFixed, setTitleIsFixed] = useState(showHero)
    const [linkIsHovered, setLinkIsHovered] = useState(false)
    const [paddingTop, setPaddingTop] = useState<string | undefined>(typeof document !== "undefined" ? `${document.documentElement.offsetHeight}` : `0px`)

    const breakpoint = useBreakpoint()

    const setPaddingTopFromDocHeight = () => {
        setPaddingTop(`${document.documentElement.offsetHeight}px`)
    }

    /*
     * We're fixing the title position when we're at the top
     * of the page until the page scrolls up a bit, which coincides
     * with the links/mobile nav toggle being visible a certain
     * amount dependent on whether we're on mobile/desktop.
     * To check on desktop we need to use IntersectionObserver not
     * useInView as it's conditionally set when the nav is first
     * not hidden.
     */
    const linksRef = useRef<HTMLDivElement | null>(null)
    const linksInView = useInView(linksRef, {
        margin: `0px 0px -68px 0px`,
    })

    useEffect(() => {
        if (breakpoint !== "mobile" && showHero) {
            setTitleIsFixed(!linksInView)
        }
    }, [linksInView, showHero, breakpoint])

    useEffect(() => {
        if (!showHero) {
            setTitleIsFixed(false)
        }
    }, [showHero])

    const debouncedResize = debounce(() => {
        setPaddingTopFromDocHeight()
    }, 300)

    useEffect(() => {
        setPaddingTopFromDocHeight()

        window.addEventListener("resize", debouncedResize)

        return () => {
            window.removeEventListener("resize", debouncedResize)
        }
    }, [])

    useEffect(() => {
        if (navIsVisible && breakpoint === "mobile") {
            document.body.classList.add("mobile-nav-open")
            disableTouchScroll()
        } else {
            document.body.classList.remove("mobile-nav-open")
            enableTouchScroll()
        }
    }, [navIsVisible, breakpoint])

    const handleMobileToggleClick = () => {
        new Promise<void>((resolve) => {
            if (showHero && window.scrollY < document.documentElement.offsetHeight) {
                animateScrollTo(document.documentElement.offsetHeight).then(() => resolve())
            } else {
                resolve()
            }
        }).finally(() => {
            setNavIsVisible((prevState) => !prevState)
        })
    }

    const closeMobileNav = () => {
        if (breakpoint === "mobile") {
            setNavIsVisible(false)
        }
    }

    const handleHomeClick = (e: React.MouseEvent) => {
        closeMobileNav()

        if (isHome || (isPrivate && !parent)) {
            e.preventDefault()
            animateScrollTo(showHero && window.scrollY < document.documentElement.offsetHeight ? document.documentElement.offsetHeight : 0, { minDuration: 1000 }) // Scroll to content if on hero
        }
    }

    const handleOverviewClick = () => {
        closeMobileNav()

        if (isHome && showHero) {
            animateScrollTo(showHero ? document.documentElement.offsetHeight : 0)
        }
    }

    const handleLinkMouseEnter = () => {
        setLinkIsHovered(true)
    }

    const handleLinkMouseLeave = () => {
        setLinkIsHovered(false)
    }

    let links: ReactNode | ReactNode[]
    if (altNav) {
        links = altNav.map((item) => {
            if (!item.uri) {
                return null
            } else {
                return (
                    <Link
                        key={`${item.uri}${item.text}`}
                        to={item.uri}
                        activeClassName={!linkIsHovered ? linkActive : null}
                        onClick={closeMobileNav}
                        onMouseEnter={handleLinkMouseEnter}
                        onMouseLeave={handleLinkMouseLeave}
                        style={{ fontStyle: item.isItalic ? "italic" : undefined }}
                    >
                        {item.text}
                    </Link>
                )
            }
        })
    } else if (!isPrivate) {
        links = (
            <>
                <Link to="/" activeClassName={!linkIsHovered ? linkActive : null} onClick={handleOverviewClick} onMouseEnter={handleLinkMouseEnter} onMouseLeave={handleLinkMouseLeave}>
                    Overview
                </Link>
                <Link to="/motion/" activeClassName={!linkIsHovered ? linkActive : null} onClick={closeMobileNav} onMouseEnter={handleLinkMouseEnter} onMouseLeave={handleLinkMouseLeave}>
                    Motion
                </Link>
                <Link to="/stills/" activeClassName={!linkIsHovered ? linkActive : null} onClick={closeMobileNav} onMouseEnter={handleLinkMouseEnter} onMouseLeave={handleLinkMouseLeave}>
                    Stills
                </Link>
                <Link
                    to="/info/"
                    activeClassName={!linkIsHovered ? linkActive : null}
                    state={{ isPrivate }}
                    onClick={closeMobileNav}
                    onMouseEnter={handleLinkMouseEnter}
                    onMouseLeave={handleLinkMouseLeave}
                >
                    Info
                </Link>
            </>
        )
    }

    return (
        <div
            className={nav}
            style={{
                paddingTop: paddingTop,
            }}
        >
            <Link to={isPrivate && parent?.uri ? parent.uri : "/"} state={{ titleClick: true }} onClick={handleHomeClick} className={`${titleEl} ${titleIsFixed ? titleElFixed : ""}`}>
                <span className={titlePrivateText}>{isPrivate && `${parent?.title ?? currentTitle} X `}</span>
                Caroline Leeming
            </Link>
            <div
                ref={linksRef}
                className={`${navLinks} nav-links ${isPrivate && Array.isArray(links) && links.length < 3 ? navLinksFew : ""} ${Array.isArray(links) && links.length === 1 ? navLinksSingle : ""}`}
            >
                {links}
            </div>
            <button
                onClick={handleMobileToggleClick}
                className={`${navMobileToggle} nav-mobile-toggle ${!showHero ? navMobileToggleFixed : ""} ${Array.isArray(links) && links.length === 1 ? navMobileToggleHidden : ""}`}
            >
                {navIsVisible ? <em>Close</em> : <span>Menu</span>}
            </button>
        </div>
    )
}
